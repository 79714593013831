










































































import Vue from 'vue'
import Component from 'vue-class-component'
import AuthService from '@/services/Auth.service'
import { mapActions, mapGetters } from 'vuex'

@Component({
  methods: {
    ...mapActions({
      login: 'auth/login'
    })
  },

  computed: {
    ...mapGetters({
      user: 'auth/user',
      authenticated: 'auth/authenticated'
    })
  }
})
export default class LoginPage extends Vue {
  authService = new AuthService();

  login!: Function

  credentials = {
    email: '',
    senha: ''
  }

  state: { loading: boolean; errors: any[] } = {
    loading: false,
    errors: []
  }

  onSubmit() {
    this.state.loading = true

    this.login(this.credentials)
      .then(() => {
        this.$router.push('/')
      })
      .catch((request) => {
        this.state.errors = [(request.response && request.response.data) || request.message]
      })
      .then(() => {
        this.state.loading = false
      })
  }
}

